exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-comisiones-tsx": () => import("./../../../src/pages/comisiones.tsx" /* webpackChunkName: "component---src-pages-comisiones-tsx" */),
  "component---src-pages-despachos-de-cobranza-index-tsx": () => import("./../../../src/pages/despachos-de-cobranza/index.tsx" /* webpackChunkName: "component---src-pages-despachos-de-cobranza-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-aviso-de-privacidad-tsx": () => import("./../../../src/pages/legal/aviso-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-legal-aviso-de-privacidad-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-prestamo-tsx": () => import("./../../../src/pages/prestamo.tsx" /* webpackChunkName: "component---src-pages-prestamo-tsx" */),
  "component---src-pages-spei-tsx": () => import("./../../../src/pages/spei.tsx" /* webpackChunkName: "component---src-pages-spei-tsx" */)
}

